.outside-wrapper {
    padding-top: 20px;
    width: 70%;
    
    @media screen and (min-width: 2150px) {
        width: 50%;
    }
}

.slider {
    width: 100%;    
    position: relative;
    overflow: hidden;
    padding-top: 66.66%;
}

.slide {
    position: absolute;    
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translateX(-50%);
    transition: all 0.5s ease;
}

//for lazy load working on decision
.slide-image {    
    background-color: var(--black-color);    
    border-radius: 10px;
}

.slide img {
    height: 100%;
    width: 100%;
    background-color: var(--black-color);
    border-radius: 10px;
}

.title {
    position: absolute;
    border-radius: 20px;
    top: 3rem;
    left: 5rem;
    opacity: 0;
    width: fit-content;  
    padding: 1rem;  
    background: rgba(0, 0, 0, 0.6);
    animation: slide-down 1s ease .5s;
    animation-fill-mode: forwards;
    // visibility: hidden;
}

@keyframes slide-down {
    0% {
        visibility: visible;
        top: 1rem;
    }
    100% {
        visibility: visible;
        left: 3rem;
    }
}

.title > * {
    color: #fff;
    font-size: 2.2rem;
    margin-bottom: 1rem;
}

.content {
    position: absolute;
    border-radius: 20px;
    bottom: 3rem;
    left: 5rem;
    opacity: 0;    
    width: 90%;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.6);
    animation: slide-up 1s ease .5s;
    animation-fill-mode: forwards;
    // visibility: hidden;
}

@keyframes slide-up {
    0% {
        visibility: visible;
        bottom: 1rem;
    }
    100% {
        visibility: visible;
        bottom: 3rem;
    }
}

.content > * {
    color: #ffffff;
    font-weight: 600;
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.current {    
    opacity: 1;
    transform: translateX(0);    

    @media screen and (max-width: 1200px){
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.current .content {
    opacity: 1;
}

.current .title {
    opacity: 1;
}

.arrow {
    border: 2px solid #08fd00;
    border-radius: 50%;
    top: 50%;
    background: transparent;
    color: #fff;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    position: absolute;
    z-index: 999;
}

.arrow:hover {
    background: #08fd00;
    color: #777;
}

.next {    
    right: 1rem;
}

.prev {    
    left: 1rem;
}

hr {
    border: 1px solid #00ff15;
}

@media screen and (max-width: 1150px) {
    .slider {
        padding-top: 88.88%;
    }

    .slide img {
        width: 100%;
        height: 80%;
    }

    .outside-wrapper {        
        width: 100%;
    }

    .arrow {
        width: 3rem;
        height: 3rem;
    }   

    .title {
        top: 1px;
        border-top: 2px solid #8400ff;
        height: 20%;
        width: 100%;
    }

    .title > * {
        color: #fff;
        font-size: 3rem;
        margin-top: 1rem;
        text-align: center;
        border-bottom: 1px solid white;
    }

    @keyframes slide-down {
        0% {
            visibility: visible;
            top: 1px;
        }
        100% {
            visibility: visible;
            left: 1px;
        }
    }

    .content {
        box-sizing: border-box;
        border-bottom: 2px solid #8400ff;
        width: 100%;
        left: -50px;
        bottom: 2px;
        height: 25%;
    }

    .content > * {        
        color: #ffffff;
        font-weight: 500;
        font-size: 2rem;
        margin-top: 1rem;
        text-align: center; 
    }

    @keyframes slide-up {
        0% {
            visibility: visible;
            left: -50px;
        }
        100% {
            visibility: visible;
            bottom: 2px;
            left: 1px;
        }
    }

    .top-line {
        display: none;
    }

    .bottom-line {
        display: none;
    }
}

@media screen and (max-width: 1150px) {
    .title > * {        
        font-size: 2.5rem;                
    }
    .content > * {           
        font-size: 1.7rem; 
        margin-top: .5rem;
    }
}

@media screen and (max-width: 950px) {
    .arrow {
        width: 2rem;
        height: 2rem;
    }  

    .title > * {        
        font-size: 2rem;
        margin-top: .5rem;                
    }

    .content > * {  
        margin-top: 0rem;                      
        font-size: 1.4rem; 
    }
}

@media screen and (max-width: 790px) {
    .next {
        right: 1rem;
    }

    .prev {
        left: 1rem;
    }  
    
    .content > * {                
        font-weight: 400;
        font-size: 1.2rem; 
    }
    
}

@media screen and (max-width: 650px) {
    .title > * {        
        font-size: 1.5rem;
        margin-top: .5rem;                
    }
    .content > * {                        
        font-size: 1rem; 
    }
}

@media screen and (max-width: 560px) {
    .title > * {                
        margin-top: 0rem;                
    }

    .content > * {                        
        font-size: .8rem; 
    }
}

@media screen and (max-width: 460px) {
    .title > * {        
        font-size: 1.2rem;        
    }

    .content > * {                
        font-weight: 300;
        font-size: .7rem; 
    }
}
