.app__works {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__work-filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    margin: 4rem 0 2rem;

    .app__work-filter-item {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: #fff;
        color: #000;
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;
        margin: 0.5rem;

        &:hover {
            background-color: var(--secondary-color);
            color: #fff;
        }

        @media screen and (min-width: 2000px) {
            padding: 1rem 2rem;
            border-radius: 0.85rem;
        }
    }

    .item-active {
        background-color: var(--secondary-color);
        color: #fff;
    }
}



.app__work-img {
    width: 100%;
    height: 230px;

    position: relative;

    img {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        object-fit: cover;
    }

    @media screen and (min-width: 2000px) {
        height: 350px;
    }
}

//alters the effect of the hovering over the image
.app__work-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);

    border-radius: 0.5rem;
    opacity: 0;
    transition: all 0.3s ease;

    //controls circles around the github icons
    div {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba(0,0,0,0.5);
        color: #fff;

        margin: 1rem;
        font-family: var(--font-base);
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;

        //changes the color of the icons
        svg {
            width: 50%;
            height: 50%;
            color: var(--white-color);
        }
    }
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    margin: 0 auto;
    max-width: 100%; 
    width: 800px;
    
    @media screen and (max-width: 960px) {
        width: 600px;
    }

    @media screen and (max-width: 800px) {
        width: 320px;
    }
}

.project-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    background-color: #fff;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
    border-radius: 10px;     

    @media screen and (max-width: 800px) {
        flex-direction: column;        
        position: relative;
    }
}


.project-img {    
    flex: 1;    
    overflow: hidden;     

    @media screen and (max-width: 800px) {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;        
    }
}

.project-img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.project-details {    
    flex: 2;
    padding: 20px;
    margin-left: 30px;

    @media screen and (max-width: 800px) {
        margin-left: 10px;
    }
}

.title-container {
    
    @media screen and (max-width: 800px) {
        background-color: #fff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 5px;
        text-align: center;
        font-size: 1.1rem;
        position: absolute;
        width: 80%;
        transform: translate(0%,-130%);
    }
}

.project-title {
    color: #351898;
    font-size: 1.8rem;
    font-weight: 400px;
    margin: 0 0 5px; 

    @media screen and (max-width: 800px) {
        font-size: 1.2rem;        
    }
}

.project-desc {
    font-size: 14px;
}

.project-info {
    display: flex;
    list-style-type: none;    
    margin-top: 7px;
    padding: 0;

    li {
        margin-right: 30px;
        color: #555;
        font-size: 14px;
        font-weight: 700;
        margin-right: 50px;

        strong {
            display: block;
            color: #351898;
            font-size: 16px;
        }
    }  

    @media screen and (max-width: 800px) {
        
        li {
            
            strong {
                font-size: 14px;
            }
        }
        
    }

}

.project-info-container {
    display: flex;

    h4 {
        font-size: 1.1rem;
    }

    @media screen and (max-width: 800px) {
        flex-direction: column;

        h4 {
            font-size: 1rem;
        }       
    }
}

.project-ingredients {
    width: 33%;
    list-style-type: none;

    li {
        margin-left: 5px;
    }

    @media screen and (max-width: 800px) {
        font-size: .8rem;
        margin-bottom: 20px;
    }
}

.project-desc-container {
    width: 77%;

    .project-btn {
        position: absolute;
        margin-left: 215px;
        margin-top: -2px;

        @media screen and (max-width: 960px) {
            margin-left: 100px;
        }

        @media screen and (max-width: 800px) {
            margin-left: 30px;   
            margin-top: 30px;         
        }
    }   

    @media screen and (max-width: 800px) {
        width: 100%;         
    }
}

.project-btn {
    background-color: var(--red-color);
    color: var(--yellow-color);
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: .8rem;
    font-weight: 600;
    padding: 15px 20px;
    letter-spacing: 1px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);

    span {        
        font-size: 1.2rem;
    }
}