.app__footer {
    flex: 1;
    width: 100%;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.app__contact-block {
    width: 100%;
    padding: 1rem; 
    position: relative;   
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    .contact-logo { 
        position: absolute;    
        z-index: -100;           
        width: 60%;        
        scale: 100%;              
    }

    @media screen and (min-width: 1200px) {
        .contact-logo {
            width: 50%
        }
    }

    @media screen and (min-width: 1800px) {
        .contact-logo {
            width: 40%;
        }
        
    }

    @media screen and (min-width: 2000px) {
        .contact-logo {
            width: 30%;
        }
    }

    @media screen and (max-width: 900px) {
        flex-direction: column;
        .contact-logo {
            top: 150px;
            width: 80%;
        }
        
    }  

    @media screen and (max-width: 600px) {
        .contact-logo {
            width: 100%;
        }
    }
    
}
    

.app__contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    column-gap: 0px;
    justify-content: flex-start;

    @media screen and (min-width: 1200px) {
        width: 80%;
    }

    @media screen and (min-width: 2000px) {
        width: 60%
    }


}

.app__form-header {
    box-shadow: 10px -2px 10px rgba(11, 26, 230, 0.363);
    padding: 10px;
    width: 20%;
    
    h4 {
        font-weight: 500;
        font-size: 1.5rem;
    }

    

    @media screen and (max-width: 900px) {
        width: 50%;
        padding: 5px;
    }
}

.app__contact {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;    

    @media screen and (max-width: 900px) {
        flex-direction: column;        
    }
}

.app__contact-info {
    flex: 1;    
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: fit-content;
    

    @media screen and (max-width: 900px) {
        width: 100%;
    }
}

//possibly move to main css
.outer-contact {    
    width: 90%;    
    display: flex;    
    margin: 3px;

    @media screen and (max-width: 900px) {
        width: 100%;
    }
}

//possibly move to main css
.outer-message {    
    width: 65%;    
    display: flex;    
    margin: 3px;   

    @media screen and (max-width: 900px) {
        width: 100%;
    }
}

.inner-contact {    
    display: flex;    
    width: 100%;
}

.contact-info {
    width: 100%;
    box-sizing: border-box;
    
}

.contact-message {
    width: 100%;
    box-sizing: border-box;
    
}

.contact-button {
    width: 100%;
    align-self: center;
}

.app__form-button {
    box-shadow: 10px 2px 10px rgba(11, 26, 230, 0.363);
    padding: 10px;
    width: 20%;
    align-self: last baseline;

    @media screen and (max-width: 900px) {
        width: 50%
    }
}
