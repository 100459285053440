//Global scss
.app {
    background-color: var(--primary-color);
    font-family: var(--font-base);
}

//class for the white background
.app__whitebg {
    background-color: var(--white-color);
}

//class for primary background
.app__primarybg {
    background-color: var(--primary-color);
}

//light blue background color
.app__lightblackbg {
    background-color: var(--light-black);
}

//class for a main flex container in a row
.app__container {
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: row;
}

//flex class center justified
.app__flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

//wrapper class for a flex item
.app__wrapper {
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 4rem 2rem;

    @media screen and (max-width: 450px) {
        padding: 4rem 1rem 2rem;
    }
}

//copyright class
.copyright {
    width: 100%;
    padding: 2rem 0 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    p {
        text-transform: uppercase;
        color: var(--black-color);
    }
}

//header text class
.head-text {
    font-size: 2.75rem;
    font-weight: 800;
    text-align: center;
    color: var(--black-color);
    text-transform: capitalize;

    span {
        color: var(--bright-purple);
        font-weight: 900;
        font-size: 3rem;
    }
    

    @media screen and (min-width: 2000px) {
        font-size: 3.75rem;   

        span {
            font-size: 4rem;
        }
    }

    @media  screen and (max-width: 600px) {
        font-size: 2rem;

        span {
            font-size: 2.5rem;
        }
    }
}

//paragragh text class
.p-text {
    font-size: 1rem;
    text-align: left;
    color: var(--black-color);
    line-height: 1.5;

    @media screen and (min-width: 2000px) {
        font-size: 1.75rem;        
    }

    @media screen and (max-width: 500px) {
        font-size: 1rem;
    }
}

//bold text class
.bold-text {
    font-size: 1rem;
    font-weight: 800;
    color: var(--black-color);
    text-align: left;

    @media screen and (min-width: 2000px) {
        font-size: 2rem;        
    }

    @media screen and (max-width: 450px) {
        font-size: 0.9rem;
    }
}

//used on name of employeer and connect
.name-text {
    font-weight: 400;
    color: var(--yellow-color);
    margin-top: 5px;
}

//this controls the outer div of inset displays
.outer-div {
    box-shadow: inset 2px 5px 20px #000000;    
    height: fit-content;
    border-radius: 5px;
}
//this controls the inner div of inset displays
.inner-div {    
    height: fit-content;
    background: #fff;
    box-shadow: inset 0px -10px 25px #700, 0px 5px 5px #333;
    margin: 20px 30px;     
    border-radius: 5px;
}

//social media class 
.app__social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;

    padding: 1rem;

    div {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--white-color);
        margin: 0.25rem 0;
        border: 1px solid var(--lightGray-color);

        display: flex;
        justify-content: center;
        align-items: center;

        transition: all 0.3s ease-in-out;

        svg {
            width: 15px;
            height: 15px;
            color: var(--gray-color);
        }

        &:hover {
            background-color: var(--bright-purple);
            border-color: var(--bright-purple);

            svg {
                color: var(--white-color);
            }
        }

        @media screen and (min-width: 2000px) {
            width: 70px;
            height: 70px;

            margin: 0.5rem 0;

            svg {
                width: 30px;
                height: 30px;                
            }
        }
    }
}

//class for the nav bar
.app__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 1rem;

    .app__navigation-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #cbcbcb;
        margin: 0.5rem;

        transition: background-color 0.2s ease-in-out;

        &:hover {
            background-color: var(--bright-blue);
        }

        @media screen and (min-width: 2000px) {
            width: 20px;
            height: 20px;            
        }
    }
}

@media screen and (max-width: 750px) {
    .app__navigation,
    .app__social {
        display: none;
    }

    .copyright {
        padding: 2rem;
    }
}
